<template>
  <div class="wap-kefu">
    <headerBar :title="$t('ke-fu-0')" @back="back"></headerBar>

    <div class="main">
      <img :src="kefuUrl" alt="" />
      <div class="title">
        {{ $t('ke-fu-24-xiao-shi-wei-nin-jie-cheng-fu-wu') }}
      </div>
      <div class="desc">
        {{
          $t(
            'ru-guo-yu-dao-wen-ti-xu-yao-bang-zhu-qing-lian-xi-xian-shang-ke-fu'
          )
        }}
      </div>
      <Button @click="toKefu">{{ $t('kai-shi-zi-xun') }}</Button>
    </div>
  </div>
</template>
<script>
import { Button, Popup, List, Dialog } from 'vant'
import { getToken } from '@/utils/auth'
import goodsList from '@/components/goodsList/wapList'
import headerBar from '@/components/header'
import { getShopFollowList } from '@/api/shop'
export default {
  components: {
    Button,
    headerBar,
    Popup,
    goodsList,
    List
  },
  data() {
    return {
      form: {},
      showDialog: false,
      dataList: [],
      kefuUrl: require('@/assets/imgs/kefu-bg.png')
    }
  },
  mounted() {},
  methods: {
    back() {
      this.$router.go(-1)
    },
    toKefu() {
      this.$router.push({
        name: 'wapKf',
        query: {
          uid: 1,
          name: this.$t('ke-fu')
        }
      })
    }
  }
}
</script>